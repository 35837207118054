import type { Lists, Metrics } from '../../../utils/contracts';
import type { DeviceType, OsNames } from '../../utils/utils';

import { useEffect, useContext, useState, useRef } from 'react';

import { TitleList } from '../TitleList';
import { MainTitle } from '../MainTitle';
import { Snackbar } from '../Snackbar';
import { GenericPolycard } from '../GenericPolycard';
import PaginationListOfLists from '../PaginationListOfLists/paginationListOfLists';
import AppContext from '../../providers/AppProvider';
import { useEmpty } from '../../hooks/useEmpty';
import { DenounceProfile } from '../DenunceProfile';
import { useWordings } from '../../hooks/useWordings';
import { usePaginationList } from '../../providers/Pagination';
import { useScrollPercentage } from '../../hooks/useScrollPercentage';
import useLoading from '../../hooks/useLoading';
import { ENV_PROD } from '../../../utils/constants';

interface ListsComponentWebviewProps {
  deviceType: DeviceType;
  lists: Array<Lists>;
  metrics: Metrics;
  newUrl: string;
  domain: string;
  shareLink: string;
  osName: OsNames;
  listOwnerId: string;
  isOwner: boolean;
}

export const ListsComponentWebview = ({
  deviceType,
  lists,
  metrics,
  newUrl,
  domain,
  shareLink,
  osName,
  listOwnerId,
  isOwner,
}: ListsComponentWebviewProps) => {
  const { env } = useContext(AppContext);
  const [listOfList, setListOfList] = useState<Array<Lists>>(lists);
  const {
    responseMobile,
    selectedPage,
    totalItems: paginationTotalItems,
    setTotalItems,
    updatePage,
    listPerPageMobile,
    loading,
  } = usePaginationList();
  const { loadingComponent, showLoading } = useLoading({
    modifier: 'block',
    isCard: true,
    size: 'medium',
  });
  const { emptyComponentDefault, showEmpty } = useEmpty({
    isOwner,
    isListDetail: false,
    urlLists: '',
  });

  const { scrollPercentage } = useScrollPercentage();
  const hasUpdatedRef = useRef(false);

  const { FOOTER_TEXT, FOOTER_LINK, MY_RECOMMENDATIONS } = useWordings();
  const envParam = env === ENV_PROD || env === undefined ? '' : `?env=${env}`;
  const footerUrl = `${domain}/noindex/denounce?bu=mercado_libre&product=affiliates&affiliate_user_id=${listOwnerId}`;
  const trackingId = `#tracking_id=${metrics?.tracking_id}`;

  useEffect(() => {
    showEmpty(paginationTotalItems === 0);
  }, [paginationTotalItems, showEmpty, setTotalItems]);

  const isEmpty =
    !Array.isArray(lists) ||
    lists.length === 0 ||
    !lists[0].item ||
    lists[0].item.result.polycards.length === 0;

  useEffect(() => {
    setListOfList(responseMobile);
  }, [responseMobile, selectedPage, deviceType]);

  useEffect(() => {
    if (scrollPercentage === 100 && !hasUpdatedRef.current) {
      updatePage(selectedPage + 1, listPerPageMobile);
      hasUpdatedRef.current = true;
    } else if (scrollPercentage < 100) {
      hasUpdatedRef.current = false;
    }
  }, [
    deviceType,
    scrollPercentage,
    selectedPage,
    updatePage,
    listPerPageMobile,
    hasUpdatedRef,
  ]);

  useEffect(() => {
    showLoading(loading);
  }, [loading, showLoading]);

  if (isEmpty) {
    return emptyComponentDefault;
  }

  const getCurrentUrl = (listId: string) =>
    `${newUrl}/${listId}${envParam}${trackingId}`;

  const getListName = (listName: string) =>
    listName === 'DEFAULT' ? MY_RECOMMENDATIONS : listName;

  return (
    <div className="home-lists">
      <div className="home-lists_container">
        <MainTitle
          metrics={metrics}
          shareLink={shareLink}
          deviceType={deviceType}
          env={env}
          osName={osName}
          isEmpty={isEmpty}
        />
        {listOfList?.map((list: Lists) => (
          <GenericPolycard
            key={list.id}
            listId={list.id}
            metrics={metrics}
            deviceType={deviceType}
            polycardContext={list.item.result.polycard_context}
            polycards={list.item.result.polycards}
            type="carousel"
          >
            <TitleList
              currentUrl={getCurrentUrl(list.id)}
              metrics={metrics}
              type={list.type}
              listName={getListName(list.name)}
            />
          </GenericPolycard>
        ))}
        {loadingComponent && (
          <div className="aff-social-polycard__loading-webview">
            {loadingComponent}
          </div>
        )}
        <Snackbar />
        <PaginationListOfLists />
        <DenounceProfile
          label={FOOTER_TEXT}
          link={FOOTER_LINK}
          url={footerUrl}
          deviceType={deviceType}
        />
      </div>
    </div>
  );
};
