import { useState, useEffect } from 'react';

import { throttle } from 'lodash';

interface UseScrollPercentageResponse {
  scrollPercentage: number;
}

export const useScrollPercentage = (): UseScrollPercentageResponse => {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const watchScroll = throttle(() => {
    const viewportHeight = window.innerHeight;
    const scrollTop = window.pageYOffset;
    const pageHeight = document.documentElement.scrollHeight;

    const maxScrollableHeight = pageHeight - viewportHeight;
    const percentage = (scrollTop / maxScrollableHeight) * 100;
    const percentageRounded = Math.min(100, Math.round(percentage));

    setScrollPercentage(percentageRounded);
  }, 400);

  useEffect(() => {
    window.addEventListener('scroll', watchScroll, { passive: true });
    watchScroll();

    return () => {
      window.removeEventListener('scroll', watchScroll);
    };
  }, [watchScroll]);

  return { scrollPercentage };
};
